#waaromons {
    background-color: #2c353d;
    background-repeat: no-repeat;
    background-image: url(/img/wireframe.png);
    background-attachment: scroll;
    background-position: top center;

    h1, h2, h3, h4, h5, h6 {
        color: white;
        font-family: 'metrophobic','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
    }

    p {
        color: white;
    }

    .iconBox {
        display: table-row;

        h3 {
            text-align: left;
            float: none;
            margin-bottom: 20px;

            &:after {
                margin: 10px 0 0 0;
            }
        }

        figure {
            float: left;
            display: table-cell;

            i.fa {
                width: 74px;
                height: 74px;
                font-size: 27px;
                line-height: 72px;
                border-style: solid;
                border-width: 1px;
                border-radius: 500px;
                text-align: center;
                margin-right: 22px;
                border-color: white;
                color: white;
            }
        }

        .iconContent {
            display: table-cell;
            float: none;
        }
    }
}
