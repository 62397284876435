header nav {
    .container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        a.navbar-brand {
            white-space: normal;
            text-align: center;
            word-break: break-all;

            svg, image {
                width: 300px;
                height: 75px;
            }
        }

        .navbar-nav a {
            padding-left: 1.2rem;
            padding-right: 1.2rem;
            position: relative;
            text-decoration: none;
            padding-bottom: 1rem;
            font-size:1rem;

            &:after {
                background-color: #ff5400;
                width: 0px;
                content: "";
                transition: all 0.5s;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 3px;
            }

            &:hover:after,
            &.active:after {
                width: 100%;
            }
        }
    }
}

@media (min-width: 576px) and (max-width: 991px) {
    header nav {
        .container {
            flex-wrap: wrap !important;

            a.navbar-brand {
                margin: 0 auto;
            }

            .navbar-collapse {
                flex-direction: column !important;
            }
        }
    }
}

@media (max-width: 575px) {
    header nav {
        a {
            width: 100%;
            display: block;
            text-align: center;
            font-size: 1rem;
        }

        a.navbar-brand svg, a.navbar-brand svg image {
            width: 200px;
            height: 50px;
        }
    }
}
