#bannerslogan {
    background-color: #ff5400;
    background-repeat: no-repeat;
    background-image: url('/img/sphere_wireframe.png');
    background-attachment: scroll;
    background-position: top center;
    min-height: 140px;

    .row {
        align-items: center;
    }

    h3 {
        color: white;
        font-family: 'metrophobic','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
        margin-bottom: 0px;
        text-align: center;

        &:after {
            display: none;
        }
    }

    a {
        color: white;
        border: 3px solid #fff;
        background: transparent;
        border-radius: 100px;
        padding: 15px 30px 13px;
        font-size: 13px;
        min-width: 139px;
        transition: all 0.5s;

        &:hover {
            text-decoration: none;
            background: white;
            color: black;
        }
    }
}

@media (max-width: 768px) {
    #bannerslogan {
        .col-md-3 {
            text-align: center;

            a {
                height: 50px;
                display: block;
                width: fit-content;
                margin: 20px auto 0 auto;
            }
        }
    }
}
